/* Weight */
.font-bold {
  @include font-family-bold;
}

.font-alt {
  @include font-family-alt-regular;
}

.font-alt-bold {
  @include font-family-alt-bold;
}

/* Size */
.font-xs {
  font-size: $font-size-xs;
}

.font-sm {
  font-size: $font-size-sm;
}

.font-lg {
  font-size: $font-size-lg;
}

.font-xl {
  font-size: $font-size-xl;
}

.font-xxl {
  font-size: $font-size-xxl;
}

.font-xxxl {
  font-size: $font-size-xxxl;
}

.font-xxxxl {
  font-size: $font-size-xxxxl;
}

.big-number {
  font-size: $font-size-xxxxl;
  line-height: 1;
}

/* Colour */
.font-white {
  color: $colour-white;
}

.font-dark-selected {
  color: $colour-purple-selected;
}

.font-grey-50 {
  color: $colour-grey-50;
}

.font-grey-20 {
  color: $colour-grey-20;
}

.font-orange {
  color: $color-orange-100;
}

.font-purple {
  color: $colour-purple-100;
}

.font-yellow {
  color: $color-yellow-100;
}

.font-green {
  color: $color-green-100;
}

.font-blue {
  color: $color-blue-100;
}