* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch
}

html {
  background-color: $colour-white;
  color: $colour-font-default;
  -webkit-text-size-adjust: 100%;
  height: 100vh;
}