.data-source-list {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  .data-source-item {
    border-radius: 1rem;
    border: 1px solid $colour-grey-10;
    height: calc(100% - 3rem);
    box-sizing: border-box;
    text-align: center;
    position: relative;
    margin-bottom: 3rem;

    img, .no-image {
      max-width: 62%;
      margin: 0 auto;
      height: 5rem;
    }

    .data-source-item-body {
      background-color: $colour-white;
      padding: 2rem 1rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      height: calc(100% - 6rem);
      box-sizing: border-box;
    }

    .data-source-item-footer {
      background-color: $colour-purple-3;
      padding: 3rem 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      align-self: flex-end;
      height: 6rem;
      box-sizing: border-box;

      &.data-source-item-footer-pending {
        background: $colour-purple-3 $icon-airbyte-data-source-loading no-repeat 50% 50%;
      }

      &.data-source-item-footer-error {
        background: $colour-purple-3 $icon-airbyte-data-source-error no-repeat 50% 50%;
      }

      &.data-source-item-footer-success {
        background: $colour-purple-3 $icon-tick no-repeat 50% 50%;
      }
    }
  }
}

.data-source-delete {
  display: block;
  width: 2rem;
  height: 2rem;
  background: transparent $icon-delete no-repeat 50% 50%;
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;

  &.data-source-delete-disabled {
    @include disabled;
    cursor: inherit;
  }
}

.data-source-edit {
  display: block;
  width: 2rem;
  height: 2rem;
  background: transparent $icon-refresh no-repeat 50% 50%;
  position: absolute;
  padding: 1rem;
  top: 3rem;
  right: 0;
  background-size: 1.75rem;

  &.data-source-edit-disabled {
    @include disabled;
    cursor: inherit;
  }
}

@media screen and ( min-width: $grid-xs ) {

  .data-source-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and ( min-width: $grid-lg ) {

  .data-source-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and ( min-width: $grid-xxxxl ) {

  .data-source-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}