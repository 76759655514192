.ReactModal__Content {
  color: $colour-font-default;
}

.ReactModal__Overlay {
  opacity: 0;
  background-color: $colour-purple-100-transparent-88;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modal-inner-container {
  padding: 2rem 1rem 0.5rem 1rem;
}