.form-field {
  margin-bottom: $box-xml;

  span.is-label,
  input {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  span.is-label {
    padding: $box-sm 1rem;
  }
}

.form-field-multi {
  margin-bottom: $box-xml;

  label {
    display: block;
    padding: 0.5rem 0.5rem;
  }

  input {
    display: inline;
    width: auto
  }
}

.form-field-view-only {
  margin-bottom: $box-xml;

  .is-label {
    padding: 0.5rem 0.5rem;
    width: 10rem;
    display: inline-block;
  }
}