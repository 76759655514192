.data-element-picker {
  display: flex;
  border: 1px solid $colour-form-border;
  margin-bottom: 1px;

  > div {
    flex: 1;
    padding: 1rem;

    &:first-of-type {
      border-right: 1px solid $colour-form-border;
    }
  }
}