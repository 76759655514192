.break-word {
  word-break: break-word;
}

.position-relative {
  position: relative;
}

.position-centre {
  margin-left: auto;
  margin-right: auto;
}

.transform-uppercase {
  text-transform: uppercase;
}

.full-width {
  display: block;
  width: 100%;
}

.display-flex {
  display: flex;
}

.visibility-hidden {
  visibility: hidden;
}

.bg-highlight {
  background-color: $colour-grey-10;
}

.max-readable-width {
  max-width: $max-readable-width;
}