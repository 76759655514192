[role=listbox],
.listbox-readonly {

  &[aria-disabled=true] {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 1rem;
    }

    li {
      .connector-state-icon {
        background: $colour-white $icon-airbyte-data-source-loading no-repeat 50% 50%;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 1rem;
    border: 1px solid $colour-grey-10;
    background-color: $colour-white;

    &.listbox-item-focus {
      outline: $focus-outline;
    }

    .connector-state-icon {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: $colour-success-dark $icon-add no-repeat 50% 50%;
      background-size: 75%;
    }

    &[aria-selected=true] {
      transition: $transition;

      .connector-state-icon {
        background: $colour-white $icon-tick no-repeat 50% 50%;
      }
    }
  }

  a {
    width: 100%;
    height: 15rem;
    min-height: 10rem;
    text-align: center;
    text-decoration: none;

    > div {

      &:nth-of-type(1) {
        padding: 0.5rem;
        height: calc(100% - 7.75rem);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        overflow: hidden;

        img, .no-image {
          max-width: 40%;
          max-height: 80%;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:nth-of-type(2) {
        padding: 0.75rem 0;
      }

      &:nth-of-type(3) {
        background-color: $colour-grey-10;
        height: 5rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and ( min-width: $grid-xs ) {

  [role=listbox],
  .listbox-readonly {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

     li {
       margin-bottom: 0;
     }
  }
}

@media screen and ( min-width: $grid-lg ) {

  [role=listbox],
  .listbox-readonly {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    a {
      height: 100%;
    }
  }
}

@media screen and ( min-width: $grid-xxxxl ) {

  [role=listbox],
  .listbox-readonly {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}