.oversize-actions {
  border-radius: $border-radius-md;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: $box-xl;
  color: $colour-purple-100;

  a {
    color: $colour-purple-100;
    text-decoration: none;
  }

  > li {
    margin-bottom: 2rem;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.oversize-action {
  @include font-family-alt-regular;
  background: transparent $icon-action-default no-repeat 50% 30%;
  background-color: $colour-grey-15;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  border: 1px solid $colour-grey-15;
  width: 100%;
  height: 18rem;
  border-radius: $border-radius-md;

  &:hover, &:active {
    box-shadow: $box-shadow-sm;
  }

  &.oversize-action-ie {
    background-image: $icon-action-ie;
  }

  > div {
    text-align: center;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and ( min-width: $grid-xs ) {

  .oversize-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;

    > li {
      margin-bottom: 0;
    }
  }

  .oversize-action {
    width: 100%;
  }
}

@media screen and ( min-width: $grid-md ) {
  .oversize-actions {
    grid-template-columns: 1fr 1fr 1fr;

    &.oversize-actions-2 {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and ( min-width: $grid-lg ) {
  .oversize-actions:not(.oversize-actions-3,.oversize-actions-2) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and ( min-width: $grid-xl ) {
  .oversize-actions:not(.oversize-actions-3, .oversize-actions-2) {
    grid-template-columns: minmax(1fr, 26rem) minmax(1fr, 26rem) minmax(1fr, 26rem) max-content;
  }
}