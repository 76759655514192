.menu-stack-container {
  position: absolute;
  top: 0;
  right: 0;

  ol {
    display: none;
    padding: 0;
    margin: 0;
    box-shadow: $box-shadow;

    a.menu-stack {
      text-decoration: none;
      justify-content: center;
      text-align: center;
      flex: 1;
      color: $colour-grey-30;


      &:hover,
      &:active {
        color: $colour-grey-30;
        text-decoration: underline;
      }
    }
  }

  &.stack-open {

    ol {
      display: block;
      background-color: $colour-white;
      position: absolute;
      min-height: 2rem;
      min-width: 12rem;
      width: calc(100% - 3rem);
      z-index: 1;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      right: 2rem;
      top: 1rem;
      border: 1px solid $colour-grey-10;
      box-sizing: border-box;
      max-width: 20rem;

      a {
        display: block;
        padding: 0.25rem;
        text-decoration: none;
        color: $colour-purple-100;

        &:hover,
        &:active {
          color: $colour-grey-50;
          text-decoration: underline;
        }
      }
    }
  }
}

.menu-stack {
  cursor: pointer;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;

  span {
    background-color: $colour-grey-30;
    border: 2px solid $colour-grey-30;
    border-radius: 50%;
    display: block;
    width: 0;
    height: 0;
    margin-bottom: 2px;
  }
}