/* Greyscale */
$colour-black: rgb(0, 0, 0); // #000 23/4/21
$colour-grey-50: rgb(135, 131, 149); // #878395 23/4;
$colour-grey-30: rgb(195, 192, 202); // c3c1ca 22/4/21
$colour-grey-20: rgb(226, 225, 229); // e2e1e5 22/4/21
$colour-grey-15: rgb(235, 238, 240); // #ebeef0 24/6/21
$colour-grey-10: rgb(241, 240, 242); // #f1f0f2 23/4/21
$colour-grey-5: rgb(251, 251, 252); // #fbfbfc 23/4/21
$colour-white: rgb(255, 255, 255); // #fff 23/4/21

/* Primary */
/* Purple */
$colour-purple-selected: rgb(4, 2, 11); // #04020b 28/4/21
$colour-purple-100: rgb(15, 7, 42); // #0f072a 28/4/21
$colour-purple-94: rgb(29, 22, 55); // #1d1637  28/4/21
$colour-purple-87-5: rgb(45, 38, 69); // #2d2645  28/4/21
$colour-purple-75: rgb(75, 69, 95); // #4b455f  28/4/21
$colour-purple-50: rgb(135, 131, 149); // #878395 28/4/21
$colour-purple-25: rgb(195, 193, 202);// #c3c1ca 28/4/21
$colour-purple-12: rgb(226, 225, 229); // #e2e1e5  28/4/21
$colour-purple-6: rgb(241, 240, 242); // #f1f0f2 28/4/21
$colour-purple-3: rgb(248, 247, 249); // #f8f7f9 28/4/21
$colour-purple-1-5: rgb(251, 251, 252); // #fbfbfc 28/4/21

/* Transparent */
$colour-purple-100-transparent-88: rgba(15, 7, 42, 0.88); // #0f072a 28/4/21

/* Violet */
$colour-violet-selected: rgb(76, 33, 213); //#4c21d5 28/4/21
$colour-violet-100: rgb(81, 35, 227); // #5123e3 28/4/21
$colour-violet-94: rgb(91, 48, 229); // #5b30e5 28/4/21
$colour-violet-87-5: rgb(103, 63, 230); // #673fe6 28/4/21
$colour-violet-75: rgb(124, 90, 234); // #7c5aea 28/4/21
$colour-violet-50:  rgb(168, 145, 241); // #a891f1 28/4/21
$colour-violet-25: rgb(211, 200, 248); // #d3c8f8 28/4/21
$colour-violet-12: rgb(234, 229, 252); // #eae5fc 28/4/21
$colour-violet-6: rgb(245, 242, 253); // #f5f2fd 28/4/21
$colour-violet-3: rgb(250, 248, 254); // #faf8fe 28/4/21
$colour-violet-1-5: rgb(252, 252, 255); // #fcfcff 28/4/21

/* Secondary */
$color-blue-selected: rgb(33, 149, 213); // #2195d5 28/4/21
$color-blue-100: rgb(35, 159, 227); // #239FE3  28/4/21
$color-blue-25: rgb(200, 231, 248); // #C8E7F8  28/4/21

$color-orange-selected: rgb(224, 93, 77); // #e05d4d 28/4/21
$color-orange-100: rgb(238, 99, 92); // #EE6352 28/4/21
$color-orange-25: rgb(251, 216, 212); // #fbd8d4  28/4/21

$color-yellow-selected: rgb(240, 179, 10); // #f0b30a 28/4/21
$color-yellow-100: rgb(255, 190, 11); //  #FFBE0B 28/4/21
$color-yellow-25: rgb(255, 239, 194); // #ffefc2  28/4/21

$color-green-selected: rgb(84, 193, 135); // #54c187 28/4/21
$color-green-100: rgb(89, 205, 144); // #59CD90 28/4/21
$color-green-25: rgb(213, 243, 227); // #d5f3e3 28/4/21

/* App */
/* Cards */
$colour-card-100: $colour-purple-100;
$colour-card-75: $colour-purple-94;
$colour-card-50: $colour-purple-87-5;
$colour-card-25: $colour-purple-75;

/* Fonts */
$colour-font-default: $colour-purple-100;
$colour-font-subdued: $colour-grey-50;
$colour-font-disabled: $colour-grey-30;

/* Alerts */
$colour-error-dark: $color-orange-selected;
$colour-error-light: $color-orange-25;

$colour-info-dark: $color-blue-selected;
$colour-info-light: $color-blue-25;

$colour-success-dark: $color-green-selected;
$colour-success-light: $color-green-25;

$colour-warning-dark: $color-yellow-selected;
$colour-warning-light: $color-yellow-25;

/* Forms */
$colour-form-border: $colour-purple-25;
$colour-form-bg: $colour-grey-5;
$colour-form-text-disabled: $colour-purple-50;
$colour-form-disabled: $colour-grey-30;
$colour-form-disabled-bg: $colour-grey-20;