@mixin font-family-regular {
  font-family: 'noto_sansregular', serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-bold {
  font-family: 'noto_sansbold', serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-alt-regular {
  font-family: 'BlenderPro_medium', serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.1rem;
}

@mixin font-family-alt-bold {
  font-family: 'BlenderPro_bold', serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.1rem;
}

@mixin font-family-mono {
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;;
  font-weight: normal;
  font-style: normal;
}

@mixin disabled {
  filter: grayscale(1);
  opacity: 0.4;
}

$grid-min: 20rem;
$grid-xxxs: 24rem;
$grid-xxs: 32rem;
$grid-xs: 40rem;
$grid-sm: 48rem;
$grid-md: 56rem;
$grid-lg: 64rem;
$grid-xl: 72rem;
$grid-xxl: 80rem;
$grid-xxxl: 90rem;
$grid-xxxxl: 100rem;
$grid-max: 120rem;

$box-xxs: 0.1rem;
$box-xs: 0.25rem;
$box-sm: 0.5rem;
$box-md: 0.75rem;
$box-lg: 1rem;
$box-xml: 1.5rem;
$box-xl: 2rem;
$box-xxl: 4rem;

$font-size-xxxs: 0.5rem;
$font-size-xxs: 0.625rem;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.375rem;
$font-size-xxl: 1.875rem;
$font-size-xxxl: 2.25rem;
$font-size-xxxxl: 4rem;

$animate-fast: 0.3s;
$animate-slow: 0.7s;

$transition: background $animate-fast;
$transition-opacity: opacity $animate-fast;

$border-radius-lg: 2.8125rem;
$border-radius-md: 1rem;

$focus-outline: 0.25rem solid $colour-violet-selected;

$box-shadow-sm: 0 0 1px $colour-grey-30;
$box-shadow: 0 0 0.5rem $colour-grey-30;

$max-readable-width: 36rem;