/* All */
.space {
  margin-top: $box-lg;
  margin-bottom: $box-lg;
  margin-right: $box-lg;
  margin-left: $box-lg;
}

.space-horizontal {
  margin-left: $box-lg;
  margin-right: $box-lg;
}

/* Vertical */
.space-vertical {
  margin-top: $box-lg;
  margin-bottom: $box-lg;
}

.space-vertical-xl {
  margin-top: $box-xl;
  margin-bottom: $box-xl;
}

/* Top */
.space-top {
  margin-top: $box-lg;
}

/* Bottom */
.space-bottom {
  margin-bottom: $box-lg;

}