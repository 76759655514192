input {
  border: 1px solid $colour-form-border;
  border-radius: 2rem;
  padding: $box-sm $box-lg;
  font-size: $font-size-md;
  background-color: $colour-form-bg;
  color: $colour-purple-selected;
  @include font-family-regular;
  display: block;
  width: 100%;

  &:focus {
    outline: $focus-outline;
  }

  &:focus-visible {
    outline: $focus-outline;
  }

  &::placeholder {
    color: $colour-purple-selected;
  }

  &[aria-invalid="true"] {
    border-color: $colour-error-dark;
  }

  &[max] {
    max-width: 10rem;
  }

  &[type="date"] {
    max-width: 12rem;
  }

  &[disabled] {
    color: $colour-form-text-disabled;
    background-color: $colour-form-disabled-bg;
  }
}

.input-container {
  position: relative;

  a {
    position: absolute;
    top: 0;
    right: 1rem;
    display: block;
    width: 1rem;
    height: 1rem;
    background: transparent $icon-visible no-repeat 50% 50%;
    padding: 0.75rem;

    &.password-toggled {
      background-image: $icon-visible-toggle;
    }
  }
}