[role=progressbar] {
  align-content: center;
  align-items: initial;
  margin: 0.5rem 0;
  text-transform: uppercase;
  color: $colour-grey-30;
  @include font-family-alt-regular;
  position: relative;

  li {
    margin: 0 1rem;
    flex: 1;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      border: 1px solid $colour-grey-20;
      background-color: $colour-grey-20;
      border-radius: 50%;
      color: $colour-grey-30;
      display: flex;
      justify-content: center;
      line-height: 1.6;
      padding: 0.1rem 0 0 0.1rem;
      height: 2rem;
      width: 2rem;
      margin-right: 0.25rem;
      text-align: center;
      @include font-family-alt-bold;
      font-size: $font-size-lg;
    }

    &:nth-child(1) {
      &:before {
        content: '1';
      }
    }

    &:nth-child(2) {
      &:before {
        content: '2';
      }
    }

    &:nth-child(3) {
      &:before {
        content: '3';
      }
    }

    &:nth-child(4) {
      &:before {
        content: '4';
      }
    }

    &:nth-child(5) {
      &:before {
        content: '5';
      }
    }

    &:nth-child(6) {
      &:before {
        content: '2';
      }
    }

    div {
      padding: 0.5rem;
    }

    &.current {
      &:before {
        border-color: $colour-violet-100;
        background-color: $colour-violet-100;
        color: $colour-white;
      }

      div {
        color: $colour-purple-100;
        @include font-family-alt-bold;
      }
    }

    &.previous {
      &:before {
        content: '✓';
        border-color: $colour-success-dark;
        background-color: $colour-success-dark;
        color: $colour-white;
      }

      div {
        color: $colour-success-dark;
      }
    }
  }
}

@media screen and ( min-width: $grid-sm ) {

  [role=progressbar] {
    display: flex;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
}