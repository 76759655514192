.notifications {
  max-width: 21rem;
  position: absolute;
  top: 0;

  &.notifications-stacked {
    .notification:not(:first-of-type) {
      margin-top: -2rem;

      .notification-body {
        display: none;
      }
    }
  }

  .notification {
    padding: 2rem 1rem 1rem 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: relative;
    margin-top: -1rem;

    a.a-cancel {
      margin-top: 1rem;
    }

    > div {
      font-size: $font-size-sm;
    }

    &.notification-default {
      background-color: $colour-grey-10;
      color: $colour-purple-100;
    }

    &.notification-error,
    &.notification-info,
    &.notification-warning,
    &.notification-success {
      h4 {
        color: $colour-white;
      }
    }

    &.notification-error {
      color: $colour-white;
      background-color: $color-orange-100;
    }

    &.notification-info {
      color: $colour-white;
      background-color: $color-blue-100;
    }

    &.notification-warning {
      color: $colour-white;
      background-color: $color-yellow-100;
    }

    &.notification-success {
      color: $colour-white;
      background-color: $color-green-100;
    }
  }
}

@media screen and ( min-width: $grid-xxs ) {

  .notifications {
    right: 2rem;
  }
}