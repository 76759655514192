@keyframes button-loader {
  0% {
    border-color: $colour-violet-100;
  }
  50% {
    border-color: $colour-violet-87-5;
  }
  100% {
    border-color: $colour-violet-25;
  }
}

button {
  padding: 0.75rem 2rem;
  text-transform: uppercase;
  border-radius: 3rem;
  border-color: $colour-white;
  background-color: $colour-purple-100;
  color: $colour-white;
  border: 1px solid $colour-purple-100;
  @include font-family-alt-regular;
  font-size: $font-size-xl;
  cursor: pointer;

  &:hover,
  &:active {
    border: 1px solid $colour-purple-selected;
    background-color: $colour-purple-selected;
    color: $colour-white;
  }

  &.button-slim {
    font-size: $font-size-lg;
    padding: 0.25rem 2rem;
  }

  &.button-secondary {
    border: 1px solid $colour-purple-100;
    background-color: $colour-white;
    color: $colour-purple-100;

    &:hover,
    &:active {
      border-color: $colour-purple-selected;
      background-color: $colour-purple-selected;
      color: $colour-white;
    }
  }

  &.button-delete {
    border-color: $color-orange-100;
    color: $colour-white;
    background-color: $color-orange-100;

    &:hover,
    &:active {
      border-color: $color-orange-selected;
      background-color: $color-orange-selected;
      color: $colour-white;
    }
  }

  &.button-icon {
    padding-right: 1rem;
    padding-left: 0.75rem;
    display: flex;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 1rem;
      border-radius: 50%;
      width: 0;
      height: 0;
      border: 0.75rem solid $colour-white;
    }
  }

  &[disabled] {
    background-color: $colour-form-disabled-bg;
    border-color: $colour-form-disabled-bg;
    color: $colour-form-disabled;
    cursor: unset;

    &:hover,
    &:active {
      background-color: $colour-form-disabled-bg;
      border-color: $colour-form-disabled-bg;
      color: $colour-form-disabled;
    }

    &.button-fetching {
      background-color: $colour-purple-selected;
      border-color: $colour-purple-selected;
      color: $colour-purple-selected;

      &.button-delete {
        border-color: $color-orange-100;
        color: $colour-white;
        background-color: $color-orange-100;
      }

      > div {
        display: flex;
        justify-content: center;
        padding: 0.375rem;

        > span {
          animation: button-loader 600ms infinite alternate linear;
          display: block;
          width: 0;
          height:0;
          margin: 0.25rem 0.15rem;
          border: 0.25rem solid $colour-violet-100;
          border-radius: 50%;

          &:nth-child(1) {
            border-color: $colour-violet-100;
            animation-delay: 0ms;
          }
          &:nth-child(2) {
            border-color: $colour-violet-87-5;
            animation-delay: 300ms;
          }
          &:nth-child(3) {
            border-color: $colour-violet-25;
            animation-delay: 600ms;
          }
        }
      }
    }
  }

  + button {
    margin-left: $box-sm;
  }
}