@keyframes three-quarters-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  text-align: center;
  text-transform: uppercase;

  .spinner-outer {
    background: transparent $icon-loading-1 no-repeat 50% 50%;
    width: 2rem;
    height: 2rem;
    box-sizing: border-box;
    display: inline-block;
  }

  .spinner {
    animation: three-quarters-loader 1250ms infinite linear;
    border: 0.125rem solid $colour-violet-100;
    color: $colour-violet-100;
    border-right-color: transparent;
    border-radius: 1rem;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 2rem;
    height: 2rem;
  }

  small {
    @include font-family-alt-regular;
    color: $colour-violet-100;
  }
}