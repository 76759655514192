@font-face {
  font-family: 'noto_sansregular';
  src: url('../../assets/fonts/notosans-regular-webfont.woff2') format('woff2'),
  url('../../assets/fonts/notosans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'noto_sansbold';
  src: url('../../assets/fonts/notosans-bold-webfont.woff2') format('woff2'),
  url('../../assets/fonts/notosans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlenderPro_medium';
  src: url('../../assets/fonts/685030e1abf58bd087a4ee725d9dea6a.woff2') format('woff2'),
  url('../../assets/fonts/685030e1abf58bd087a4ee725d9dea6a.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlenderPro_bold';
  src: url('../../assets/fonts/a082fd3df68a0b54e0d4d794bc38d268.woff2') format('woff2'),
  url('../../assets/fonts/a082fd3df68a0b54e0d4d794bc38d268.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}