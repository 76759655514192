.data-grid-toolbar-container {
  border-top: 1px solid $colour-grey-10;

  &:empty {
    margin-bottom: 2rem;
  }
}

.data-grid-toolbar-wrapper {
  background-color: $colour-grey-10;
  width: 100%;
  max-width: 26rem;
  margin: 0 auto 2rem auto;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  font-size: $font-size-xs;
  padding: 0 0.25rem;
  box-sizing: border-box;
}

.data-grid-toolbar {
  display: flex;
  align-items: flex-start;

  > li {
    &.toolbar-search {
      flex: fit-content;
    }

    &.toolbar-tabs {
      flex: auto;
      height: 100%;
    }

    &.toolbar-layout {
      flex: fit-content;
    }

    &.toolbar-filter {
      flex: fit-content;
      visibility: hidden;
    }
  }
}


#dataGridToolbarElements {
  padding: 0.5rem 0.1rem;

  .form-field {
    margin-bottom: 0;
  }
}

@media screen and ( min-width: $grid-sm ) {
  .data-grid-toolbar-wrapper {
    width: 80%;
  }
}

@media screen and ( min-width: $grid-md ) {
  .data-grid-toolbar-wrapper {
    width: 50%;
  }
}