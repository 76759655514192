.data-grid {

  border-radius: $border-radius-md;
  border: 1px solid $colour-grey-10;
  color: $colour-purple-selected;
  margin-bottom: 1rem;

  /* Tile */
  &.data-grid-tile {
    border-color: transparent;
    display: grid;
    grid-row-gap: 1rem;

    .data-grid-header {
      display: none;
    }

    > li {
      border: 1px solid $colour-grey-10;
      border-radius: $border-radius-md;
      color: $colour-grey-50;

      &:first-of-type {
        display: none;
      }

      > div {
        height: 100%;
        box-sizing: border-box;
      }
    }

    .data-grid-item {
      padding: 1rem;

      &.data-grid-item-tile {
        box-sizing: border-box;
        height: 100%;

        > li {
          padding-bottom: $box-xs;
        }
      }
    }
  }

  /* Grid */
  &.data-grid-grid {

    > li {
      border-bottom: 1px solid $colour-grey-10;
      background-color: $colour-white;
      height: 100%;

      &:nth-child(even) {
        background-color: $colour-grey-5;
      }

      &:last-of-type {

        border-bottom-width: 0;

        ol {
          border-bottom-width: 0;
        }
      }
    }

    .data-grid-header,
    .data-grid-item {
      display: flex;
      padding: 0;
      align-items: center;
      height: 100%;
      box-sizing: border-box;

      > li {
        flex: 1;
        padding-left: $box-xs;
        padding-right: $box-xs;
        border-right: 1px solid $colour-grey-10;
        height: 100%;

        &:last-of-type {
          border-right-width: 0;
          padding-right: 1.5rem;
        }
      }
    }

    .data-grid-header {
      text-transform: uppercase;
      text-align: center;
      color: $colour-grey-50;
      @include font-family-alt-bold;
      font-size: $font-size-xl;

      > li {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }

    .data-grid-item {
      text-align: center;
      color: $colour-grey-50;

      > li {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
  }
}

/* TMP Fishes! */
.data-grid-item {

  &.project-icon-tmp-0,
  &.project-icon-tmp-1,
  &.project-icon-tmp-2,
  &.project-icon-tmp-3,
  &.project-icon-tmp-4,
  &.project-icon-tmp-5,
  &.project-icon-tmp-6 {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      background: transparent $icon-project-1 no-repeat 50% 50%;
      background-size: cover;
      position: absolute;
      bottom: 2.5rem;
      right: 0.5rem;
    }
  }

  &.data-grid-item-grid {

    &.project-icon-tmp-0,
    &.project-icon-tmp-1,
    &.project-icon-tmp-2,
    &.project-icon-tmp-3,
    &.project-icon-tmp-4,
    &.project-icon-tmp-5,
    &.project-icon-tmp-6 {
      &:after {
        bottom: 0;
        right: 0;
      }
    }
  }

  &.project-icon-tmp-0 {
    &:after {
      background: transparent $icon-project-1 no-repeat 50% 50%;
    }
  }

  &.project-icon-tmp-1 {
    &:after {
      background: transparent $icon-project-1 no-repeat 50% 50%;
    }
  }

  &.project-icon-tmp-2 {
    &:after {
      background: transparent $icon-project-2 no-repeat 50% 50%;
    }
  }

  &.project-icon-tmp-3 {
    &:after {
      background: transparent $icon-project-3 no-repeat 50% 50%;
    }
  }

  &.project-icon-tmp-4 {
    &:after {
      background: transparent $icon-project-4 no-repeat 50% 50%;
    }
  }

  &.project-icon-tmp-5 {
    &:after {
      background: transparent $icon-project-5 no-repeat 50% 50%;
    }
  }

  &.project-icon-tmp-6 {
    &:after {
      background: transparent $icon-project-5 no-repeat 50% 50%;
    }
  }
}

@media screen and ( min-width: $grid-xxs ) {
  .data-grid {
    &.data-grid-tile {
      grid-template-columns: 1fr;
      grid-column-gap: 1rem;

    }
  }
}

@media screen and ( min-width: $grid-xs ) {
  .data-grid {
    &.data-grid-tile {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and ( min-width: $grid-lg ) {
  .data-grid {
    &.data-grid-tile {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media screen and ( min-width: $grid-xxxl) {
  .data-grid {
    &.data-grid-tile {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

@media screen and ( min-width: $grid-xxxxl) {
  .data-grid {
    &.data-grid-tile {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}