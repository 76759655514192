a {
  text-decoration: underline;
  cursor: pointer;
  transition: background 0.3s;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &.a-cancel {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
    cursor: pointer;
    background: transparent $icon-close no-repeat 50% 50%;
    background-size: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.a-add {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    background: $color-green-100 $icon-add no-repeat 50% 50%;
    background-size: 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 50%;
  }

  &.a-sortable {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-content: center;

    &:after {
      content: '';
      display: block;
      width: 1rem;
      padding: 1rem 1.25rem;
      height: 100%;
      box-sizing: border-box;
      background: transparent $icon-sortable no-repeat 50% 40%;
    }
  }

  &.a-filters {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    background: transparent $icon-filter no-repeat 50% 50%;
  }

  &.a-search {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    background: transparent $icon-search no-repeat 50% 50%;
  }

  &.a-layout-grid {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    background: transparent $icon-layout-grid no-repeat 50% 50%;
  }

  &.a-layout-tile {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    background: transparent $icon-layout-tile no-repeat 50% 50%;
  }

  &[download] {
    color: $colour-font-default;
    background: transparent $icon-download no-repeat 100% 50%;
    padding-right: 1.5rem;
    text-decoration: none;
    font-size: $font-size-sm;
    @include font-family-alt-regular;
    text-transform: uppercase;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &.a-block-wrapper {
    text-decoration: none;
    display: block;
    border-radius: $border-radius-md;
    height: 100%;
    box-sizing: border-box;

    &:active,
    &:hover {
      text-decoration: none;
      box-shadow: inset 1px 1px 2px $colour-grey-30, inset -1px -1px 2px $colour-grey-30;
    }
  }
}