.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  user-select: none;
  left: 0;
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
}