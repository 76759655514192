.align-centre {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.flex-align-center {
  align-self: center;
}