div[id^="describe-text"],
div[id^="describe-error"] {
  font-size: $font-size-xs;
  padding: 0.25rem 1rem;
}

div[id^="describe-text"] {
  color: $colour-grey-50;
}

div[id^="describe-error"] {
  color: $colour-error-dark;
  display: flex;

  &:before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.25rem;
    background: $colour-error-dark $icon-info no-repeat 50% 50%;
  }
}