.data-transform-container {
  height: 80vh;
  display: grid;
  grid-template-columns: auto 20rem;
  border: 1px dashed $colour-grey-30;
  border-radius: 1rem;

  aside {
    border-left: 1px dashed $colour-grey-30;
    padding: 1rem;
    background-color: $colour-white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    overflow-scrolling: touch;
  }
}

.data-designer-sidebar {
  flex: 1;

  .sidebar-item {
    border-bottom: 1px solid $colour-form-border;
    padding: 0.5rem 0;
    user-select: none;

    &:first-of-type {
      border-top: 1px solid $colour-form-border;
    }
  }
  .sidebar-header {
    display: block;
    text-transform: uppercase;
    @include font-family-alt-regular;
    font-size: $font-size-xl;
    cursor: pointer;
    text-decoration: none;
    background: transparent $icon-chevron-down no-repeat 100% 50%;
    line-height: 1;
    margin: 0.5rem;

    &[aria-expanded=true] {
      background-image: $icon-chevron-up;
    }

    &.sidebar-item-disabled {
      background-image: none;
      cursor: inherit;
      color: $colour-font-disabled;
    }
  }
}

.data-designer-actions {
  display: flex;
  border-bottom: 1px solid $colour-form-border;
  padding-bottom: 1rem;
  text-transform: uppercase;
  @include font-family-alt-regular;

  li {
    flex: 1;
    margin: 0 0.25rem;

    &:nth-of-type(1) {
      margin-left: 0;

      a {
        background-image: $icon-play;
        background-size: 0.75rem;
      }
    }

    &:nth-of-type(2) {
      a {
        background-image: $icon-refresh;
      }
    }

    &:nth-of-type(3) {
      a {
        background-image: $icon-close;
        background-size: 0.75rem;
      }
    }

    &:nth-of-type(4) {
      margin-right: 0;

      a {
        background-image: $icon-ingest;
        background-size: 1.2rem;
      }
    }

    a {
      display: block;
      padding: 1.75rem 0 0.5rem 0;
      font-size: $font-size-sm;
      text-decoration: none;
      text-align: center;
      border: 1px solid $colour-purple-50;
      color: $colour-purple-100;
      border-radius: 0.5rem;
      background: transparent $icon-ingest no-repeat 50% 20%;
      background-size: 1rem;

      &:hover,
      &:active {
        color: $colour-purple-selected;
        border-color: $colour-purple-25;
        background-color: $colour-purple-3;
      }
    }
  }
}

.data-transform-canvas-container {
  min-height: 40rem;
  padding: 1rem;
}

.data-transform-dsl-container {
  border-top: 1px solid $colour-grey-10;
  padding: 1rem;
  background-color: lightblue;

  > div {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 0.25rem;
  }

  .dsl-mapping {
    padding-bottom: 1rem;
  }

  .dsl-mapping-item {
    padding-left: 3rem;
  }

  .dsl-query {
    padding-left: 5rem;
  }
}